import React, { forwardRef } from "react"
import { css } from "@emotion/core"

import { colors, mediaQueries, smSectionHead, container } from "../../styles"
import FullWidthSection from "../FullWidthSection"

const InsightsSlider = forwardRef((props, ref) => {
  const titleStyles = css`
    text-align: center;
    margin-bottom: 60px;

    ${mediaQueries.phoneLarge} {
      margin-bottom: 70px;
    }
  `
  return (
    <FullWidthSection
      ref={ref}
      height="0"
      backgroundColor={colors.lightblue}
      css={css`
        padding-top: 25px;
        padding-bottom: 60px;
        padding-left: 0;
        padding-right: 0;

        ${mediaQueries.phoneLarge} {
          padding-bottom: 115px;
          padding-top: 90px;
        }
      `}
    >
      <div css={[container.max]}>
        <h2 css={[smSectionHead, titleStyles]}>経営理念</h2>
        <p>
          シードシステム株式会社の企業活動におけるポリシーは、
          「従業員」、「顧客」、「パートナー」、「投資家」という4者の「満足（Satisfaction）」を永続的に高めて行くことこそが企業の付加価値を増大させ、ひいては社会の発展に貢献し得る方法である、という信念に基づいて企業活動を展開しております。
        </p>
        <p>
          シードシステムは優秀なIT人材を土台として、お客様に、高品質かつ低コストのサービスの提供を目標として、
          追求し続ける会社です。
        </p>
        <ul>
          <li>
            社員に活躍の場を与え、社員と会社がお互いの夢を共有できる企業とする。
          </li>
          <li>人々の暮らしに役立つ商品を創出し供給し続ける。</li>
          <li>利潤追求の前に良識ある市民としての立場で物事を考え行動する。</li>
          <li>お取引様、地域社会、社員に対して誠実であり続ける。</li>
        </ul>
      </div>
    </FullWidthSection>
  )
})

export default InsightsSlider
