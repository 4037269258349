import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import FullWidthSection from "../FullWidthSection"
import { colors, mediaQueries, smSectionHead, container } from "../../styles"

const logoList = [
  "株式会社CAC",
  "株式会社富士通ネットワークソリューションズ",
  "株式会社日立ソリューションズ",
  "イーピーエス株式会社",
  "株式会社内田洋行ITソリューションズ",
  "株式会社アルル",
  "MBPジャパン株式会社",
  "株式会社オーライソフトウエア",
  "日本情報システム株式会社",
  "JAソーラー・ジャパン株式会社",
  "株式会社アルゴー",
  "ポピュラーソフト株式会社",
  "株式会社eビジネスソリューションズ",
  "株式会社ナレッジリンク",
  "ひばりネットシステム株式会社",
  "株式会社CIJソリューションズ",
  "株式会社ケイ開発",
  "株式会社日立ソリューションズ・クリエイト",
]

const LogoGrid = ({ title }) => {
  const Logos = styled.div`
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    margin-bottom: -60px;

    ${mediaQueries.phoneLarge} {
      flex-wrap: wrap;
    }
  `

  const logoItem = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin-bottom: 50px;

    ${mediaQueries.phoneLarge} {
      flex: 0 0 33%;
      width: 33%;
      max-width: 33%;
      margin-bottom: 50px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  `

  const titleStyles = css`
    text-align: center;
    margin-bottom: 60px;

    ${mediaQueries.phoneLarge} {
      margin-bottom: 70px;
    }
  `

  const containerStyles = css`
    padding-top: 50px;
    padding-bottom: 50px;

    ${mediaQueries.phoneLarge} {
      min-height: 0;
      padding-top: 85px;
      padding-bottom: 85px;
    }
  `

  return (
    <FullWidthSection
      backgroundColor={colors.lightgray}
      minHeight="100vh"
      height="600px"
    >
      <div css={[container.max, containerStyles]}>
        <h2 css={[smSectionHead, titleStyles]}>{title}</h2>
        <Logos>
          {logoList.map((logo, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} css={logoItem}>
              {logo}
            </div>
          ))}
        </Logos>
      </div>
    </FullWidthSection>
  )
}

export default LogoGrid
