import React from "react"
import { css } from "@emotion/core"
import Img from "gatsby-image"

import { mediaQueries } from "../../styles"

const ImageCollage = ({ project }) => {
  const selectedClass = () => 1

  // Doing all this positioning the old-fashioned way because it doesn't actually
  // follow consistent layout rules that could be used to form a grid. Prepare for
  // allllll the intrinsic ratios.
  const collageWrapper = css`
    margin-bottom: 40px;

    ${mediaQueries.phoneLarge} {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-top: -20px;
      margin-bottom: 0;
      z-index: -1;

      &.type-a {
        left: 430px;
        right: 60px;

        .collage-board {
          padding-top: 74%;
        }

        .primary-wrapper {
          bottom: 8%;
          right: 20%;
          width: 70%;
          height: 70%;
        }
      }
    }
  `

  const collageBoardBase = css`
    ${mediaQueries.phoneLarge} {
      position: relative;
      height: 0;
      overflow: hidden;
    }
  `

  const primaryWrapperBase = css`
    position: relative;
    height: 0;
    padding-top: 77.6%;
    overflow: hidden;

    ${mediaQueries.phoneLarge} {
      position: absolute;
      padding-top: 0;
    }
  `

  const imageStyles = css`
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.6s ease all;

    .slick-active & {
      opacity: 1;
    }
  `

  // Pass array of fixed images with media query for art direction.
  // @see https://www.gatsbyjs.org/packages/gatsby-image/#art-directing-multiple-images
  return (
    <div className="type-a" css={collageWrapper}>
      <div className="collage-board" css={collageBoardBase}>
        <div css={primaryWrapperBase} className="primary-wrapper">
          <Img
            fluid={project.image}
            alt={project.title}
            className={`primary${selectedClass()}`}
            css={imageStyles}
          />
        </div>
      </div>
    </div>
  )
}

export default ImageCollage
