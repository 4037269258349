import React, { useRef } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ProjectsSlider from "../components/ProjectsSlider"
import LogoGrid from "../components/LogoGrid"
import SplitSection from "../components/SplitSection"
import { ContactUs, BeUs } from "../components/Prefooter"
import { useHasBeenPartlyVisible } from "../hooks/useVisibility"
import FullWidthSection from "../components/FullWidthSection"
import InsightsSlider from "../components/InsightsSlider"

export default ({ data }) => {
  const halfPage = useRef()
  const hasScrolled = useHasBeenPartlyVisible(halfPage, 0.1)
  const imageSet = [
    {
      title: "太陽光発電シミュレーションシステム",
      route: "solar",
      image: data.solarImage.childImageSharp.fluid,
    },
    {
      title: "モバイルソリューション事業",
      route: "mobile",
      image: data.mobileImage.childImageSharp.fluid,
    },
    {
      title: "パッケージソリューション事業",
      route: "package",
      image: data.packageImage.childImageSharp.fluid,
    },
    {
      title: "エンジニア・アウトソーシング事業",
      route: "outsourcing",
      image: data.outsourcingImage.childImageSharp.fluid,
    },
  ]

  return (
    <Layout
      headerData={{
        metaTitle: "トップページ",
        title: "シードシステムへようこそ",
        height: "50vh",
        mobileMinHeight: "93vh",
      }}
    >
      <ProjectsSlider imageSet={imageSet} />
      <InsightsSlider ref={halfPage} />
      {hasScrolled ? (
        <>
          <LogoGrid title="主な取引先" />
          <SplitSection>
            <ContactUs />
            <BeUs />
          </SplitSection>
        </>
      ) : (
        <FullWidthSection height="1986px" minHeight="2748px" />
      )}
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    mobileImage: file(relativePath: { eq: "mobile-dev.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    packageImage: file(relativePath: { eq: "package.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    outsourcingImage: file(relativePath: { eq: "outsourcing.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    solarImage: file(relativePath: { eq: "solar.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
