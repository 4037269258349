import React from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

import Button from "../Button"
import { smSectionHead, container, mediaQueries, weights } from "../../styles"

import ImageCollage from "./ImageCollage"

const slideStyles = css`
  text-align: center;
  ${mediaQueries.phoneLarge} {
    position: relative;
    min-height: 750px;
    padding-bottom: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`

const slideTitleWrapper = css`
  display: flex;
  width: 100%;
  min-height: 120px;
  margin-bottom: 10px;
  align-items: center;
  word-wrap: initial;
  ${mediaQueries.phoneLarge} {
    max-width: 550px;
    min-height: 194px;
    margin-bottom: 40px;
  }
`

const h1Styles = css`
  width: 100%;
  margin-bottom: 0;
  font-size: 38px;
  line-height: 1.27;
  font-weight: ${weights.black};
  letter-spacing: 0.8px;
  opacity: 0;
  transition: 0.6s ease all;

  .slick-active & {
    opacity: 1;
  }

  ${mediaQueries.phoneLarge} {
    margin-bottom: 0;
    font-size: 50px;
    line-height: 1.5;
    letter-spacing: 1.4px;
    transform: translateX(200px);
    opacity: 0;

    .slick-active & {
      transform: translateX(0);
    }
  }
`

const h3Styles = css`
  margin: 40px 0 20px;
  line-height: 1;

  ${mediaQueries.phoneLarge} {
    margin-top: 0;
  }
`

const ProjectPreview = ({ project }) => {
  return (
    <div key={project.title} css={[container.max, slideStyles]}>
      <h3 css={[smSectionHead, h3Styles]}>製品 / サービス</h3>
      <div css={slideTitleWrapper}>
        <h1 css={h1Styles}>{project.title}</h1>
      </div>
      <ImageCollage project={project} />
      <Button
        onClick={() =>
          navigate(
            project.route === "solar" ? `/solar` : `/service#${project.route}`
          )
        }
      >
        詳細
      </Button>
    </div>
  )
}

ProjectPreview.propTypes = {
  project: PropTypes.object.isRequired,
}

export default ProjectPreview
